/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');

/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #26222c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  color: hsl(192, 17%, 99%);
  
}
.header-container-dark { 
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url("./images/purple-night-sky.jpg");
  background-size: cover;
  background-repeat:   no-repeat;
  background-position: center center;
  background-attachment: fixed;
  
}

.header-container-light {
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: url("https://i.ibb.co/BTvqQhP/rich-martello-ti4p-Ygf-Dmis-unsplash.jpg");
  background-size: cover;
  background-repeat:   no-repeat;
  background-position: center center;
}
//Toggle ------------------------------------------------------------------------------
body {
  overflow: auto;
}
 //STARS ------------------------------------------------------------------------------
 @function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snow {
  $total: 200;
  position: absolute;
  width: 5px;
  height: 5px;
  background: rgb(225, 206, 233);
  border-radius: 50%;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 10000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}






body {
  background: #17151b;
}

//NAV-----------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


.nav-Container{
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  width: 100vw;
  height: 6rem;
}

.nav-Container a {
  color: hsl(180, 15%, 90%);
  text-decoration: none;
  font-size: 2rem;
  margin-top: -4rem;
  padding: 6rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  
}

.nav-Container a:after{
  font-family: 'Poppins', sans-serif;
  border-bottom: 5px solid #e68387;
    content: "";
    display: block;
    margin: 0.25em auto 0;
    transition: width 250ms ease-in-out 0s;
    width: 0;
}

.nav-Container a:hover:after {
  transition: width 100ms ease-in-out 0s;
  width: 100%;
}
//IntroText------------------------------------------------------
  .header-middle-contaier {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-self: center;
    margin-top: 15rem;
    width: 80%;
   
  }
  
  .header-middle-contaier h2 {
    font-size: 2rem;
    color: hsl(180, 15%, 90%);
    letter-spacing: 5px;
    font-family: "Courier New", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
    animation: fadeIn ease 6s;
    animation-iteration-count: 1;
    -webkit-animation: fadeIn ease 6s;
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
  
    .header-middle-contaier h1 {
    font-size: 8.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    line-height: 5.9rem;
    padding-bottom: 1rem;
  
  }
  
  .header-middle-contaier h1 .cat {
    font-family: 'Julius Sans One', sans-serif;
    font-size: 12rem;
    font-weight: 600;  
  }
  
  .header-middle-contaier p {
    align-self: center;
    font-size: 1.3rem;
    font-weight: 300;
    margin-top: 1rem;
    }

    .please-work {
      align-self: center;
      align-items: center;
      width: 60%;
    }
  .last-name {
  margin-top: 1rem;
  }
  

    //GRID ABOUT SECTION-------------------------------------------------------------------------
  
    .grid-container {
      display: grid;
      grid-template-columns: 0.4fr 0.4fr 1fr 1fr ;
      grid-template-rows: 1fr 1fr 1fr 1.3fr ;
      gap: 0px 0px;
      grid-template-areas:
        "image-container image-container about about"
        "image-container image-container about about "
        "image-container image-container skills skills"
        "image-container image-container skills skills";
        height: auto;
        width: 100vw;
    }
    .image-container { grid-area: image-container; }
    .about { grid-area: about; }
    .skills { grid-area: skills; }

    .image-container {
      background: #17151b;
      display:flex;
      flex-direction: column;
      align-items: center;
    }

    .me {
     height: auto;
     width: 80%;
     object-fit: cover;
     margin-top: 1rem; 
    }

    .myName {
      height: auto;
      width: 90%;
      margin-top: 5%;
      object-fit: cover;
      
    }

    .image-container p {
      margin-top: 5%;
      font-size: 1.3rem;
      letter-spacing: 5px;
      font-family: "Courier New", sans-serif;
      font-weight: normal;
      text-transform: uppercase;
    }

    .contact-stuff {
      height: auto;
      width: 90%;
     margin-top: 5%;
    }

    .about {
    background: whitesmoke;
    background: #26222c;
  
    }
    
    svg {
      width: 5.5rem;

    }
    .about h1 {
      color: rgb(243, 235, 235);
      padding: 1.3rem;
      font-size: 4rem;
    }

    .about p {
      text-align: left;
      color:rgb(172, 163, 163);
      padding-left: 2rem;
      font-size: 1.4rem;
    }

    .skills {
      background: #26222c;
      overflow: auto;
      border-radius: 0rem 0rem 0rem 10rem;
      
    }
    .skills h1 {
      color: rgb(243, 235, 235);
      padding: 1.3rem;
      font-size: 4rem;
      
    }

    .skills ul {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style-type: none; 
    }

    .skills ul li {
      color:rgb(172, 163, 163);
      padding: 2rem;
      font-size: 1.6rem;
      
    }
    .skillz {
      display: flex;
      flex-direction: column;
      align-items: center;
    
    }

    .skillz img {
      height: 5%;
    }

    .spanMe {
      color:#a87789;
    
    }

    .pink-bar {
      margin-top: -2rem;
      margin-left: 4.8rem;
      width: 9%;
      height: 1%;
    }

    .contact-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
    }
    .icon {
      margin: 1rem;
      transition: all 800ms ease-in-out;
    }
    
    .icon:hover {
      -ms-transform: rotate(360deg); /* IE 9 */
      -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
      transform: rotate(360deg);
    }
    
    /* Animation */
    .project-title{
      animation: typewriter 1s steps(44) 1s 1 normal both,
             blinkTextCursor 1500ms steps(44) infinite normal;
    }
    @keyframes typewriter{
      from{width: 0;}
      to{width: 5em;}
    }
    @keyframes blinkTextCursor{
      from{border-bottom-color: #a87789;}
      to{border-bottom-color: transparent;}
    }

    //Hover Effects ---------------

    // variables
$tip-distance: 24px;
$tip-triangle-width: 6px;
$tip-color-fallback: #17151b;
$tip-color: #17151b;
// style the tooltip anchor
.tooltip {
  position: relative;
  display: inline-block;
  cursor: help;
    &:after {
      content: attr(data-tip);
      position: absolute;
      bottom: $tip-distance;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      left: -10px;
      min-width: 100px;
      max-width: 300px;
      padding: 5px 7px 5px 10px;
      pointer-events: none;
      font-size: .75em;
      background-color: $tip-color-fallback;
      background-color: $tip-color;
      color: #999;
      border-radius: 2px;
    }
    &:before,  
    &:after {
      display: block;
      visibility: hidden; // because opacity:0 is not enough for IE8
      opacity: 0;  
      transition: opacity 0.2s ease-out, margin 0.2s ease-out;
    }
    &:hover {
      color: #999;
      &:before,  
      &:after {
        opacity: 1;
        margin-bottom: 3px;
        visibility: visible;
      }
    }
}

.tooltip-bottom {
  &:after {
    top: $tip-distance;
  }
  &:before {
    top: $tip-distance - $tip-triangle-width;
    border-top: 0;
    border-left: $tip-triangle-width solid transparent;
    border-right: $tip-triangle-width solid transparent;
    border-bottom: $tip-triangle-width solid $tip-color-fallback; // IE8 fallback
    border-bottom: $tip-triangle-width solid $tip-color;
  }
  &:hover {
    &:before,  
    &:after {
      bottom: auto;
      margin-bottom: 0;
      margin-top: 3px;
    }
  }
}

// Projects --------------------------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Baloo+Thambi');


.text-container{
  color: #26222c;
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
  font-size: 10rem;
  margin: 5rem;
  margin-top: -0.5rem;
  
}

.text-container > span {
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite;
}

@keyframes glow {
  0%, 100% {
    color: #a37485;
    text-shadow: 0 0 10px #a37485, 0 0 50px #a37485, 0 0 100px #ec8ae7;
  }
  
  10%, 90% {
    color: #2a2730;
    text-shadow: none;
  }
}

.text-container > span:nth-child(2) {
	animation-delay: 0.25s;
}

.text-container > span:nth-child(3) {
	animation-delay: 0.5s;
}

.text-container > span:nth-child(4) {
	animation-delay: 0.75s;
}

.text-container > span:nth-child(5) {
	animation-delay: 1s;
}

.text-container > span:nth-child(6) {
	animation-delay: 1.25s;
}

.text-container > span:nth-child(7) {
	animation-delay: 1.5s;
}

    .projects-container {
      width: 100vw;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #17151b;
      color:rgb(218, 211, 211);
      
	}

  .projects {
  width: 100vw;
  // border-radius: 2rem;
  text-align: center;
  background-color: #26222c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
}

.project-title {
  font-size: 4rem;
  position: relative; 
  margin: 0 auto;
  border-bottom: 2px dashed #a87789;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-20%);  
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}



.p-left-image{
  width: 50%;
  height: auto;
}

.p-left-content {
  width: 50%;
  display: flex;
  flex-direction: column; 
  
}
.languages-used {

  font-size: 1.1rem;
  font-family: "Courier New", sans-serif;
  margin-bottom: -1rem;
  }

.project-about-p {
  width: 80%;
  margin-top: 2rem;
  font-size: 1.5rem;
  align-self: center;

}


.project-img {
  width: 100%;
  background-color: #17151b;
  border-radius: 1rem;
  transition: transform .2s;
  position: relative;
   
}
.project-img:hover{
opacity: 1;
transform: scale(1.05);
filter: drop-shadow(0 0 0.85rem crimson);
}



.left-title{
  margin-top: 1rem;
  font-size: 5rem;
  
  // background: linear-gradient(
  //   to right bottom,
  //   rgba(65, 80, 85, 0.7),
  //   rgba(98, 56, 138, 0.3));
  // border-radius: 2rem;
  align-self: center;
  font-family: 'Actor', sans-serif;
  letter-spacing: 0.2rem;
  
}

.left-purple-title-bar {
width: 100%;
height: 50%;
background: #26222c;
}
.left-buttons{
  margin-top: 2rem; 
}

.left-buttons a {
  color: rgb(218, 211, 211);
  font-weight: bold;
  text-decoration: none;
  font-size: 2rem;
  padding: 1rem;
  margin: 1rem;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
}

.left-buttons  a:hover {
  color: #a37485;
}


.left-buttons a:before,
.left-buttons a:after {
  -webkit-transition: width .6s ease;
  -moz-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}


.project-left {
  width: 85%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex: row;
  
  background-color: #17151b;
  padding: 3rem;
  // border-radius: 1rem 2rem 15rem 1rem;
  
}
.pink-bar-projects{
align-self: center;
margin-bottom: 1rem ;
width: 30rem;
height: 0.4rem;
border-radius: 1rem;
}
//RIGHT -------------------
.project-right {
  width: 85%;
  height: 90%;
  // background: #17151b;
  padding: 3rem;
  display: flex;
  flex: row;
  // justify-content: center;
  // align-items: center;
  
  background-color: #17151b;
  padding: 3rem;
  // border-radius: 0rem 0rem 1rem 15rem;
}





.HRF {
  width: 100%;
  border-radius: 1rem;
  transition: transform .2s;
  position: relative;
}

.nasa-img:hover {
  filter: drop-shadow(0 0 0.85rem rgb(114, 52, 133));
}

.nasa-title {
  font-size: 4rem;

}

.HRF:hover {
  filter: drop-shadow(0 0 0.85rem rgb(69, 90, 160));
}

//Contact ------------------------------------------------------
.contact-container{
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26222c;
}



.pink-bar-contact {
  width: 40%;
  height: 0.5rem;
  margin-bottom: 1rem;
}
@import url('https://fonts.googleapis.com/css2?family=Yomogi&display=swap');
#container {
  color:rgb(218, 211, 211);
  text-transform: uppercase;
  font-size:8rem;
  font-weight:bold;
  height: 100%;
  width:85%;
  background-color: #17151b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;

  margin-bottom: 15rem;

}



#container h1 {
  font-family: 'Yomogi', cursive;
  letter-spacing: 1.5rem;
  font-size: 6rem;
  color:rgb(218, 211, 211);
  text-align: center;
  
}

#container h2 {
  font-size: 4rem;
  padding: 1rem;
}
 #container p {
   font-size: 3rem;
 }



#container a {
  position: relative;
  text-transform: uppercase;
  margin: 20px 0;
  padding: 10px 20px;
  text-decoration: wavy underline #676272;
  text-underline-offset: 1rem;
  color: #a37485;
  font-weight: 600;
  transition: .5s;
  z-index: 1;
  &:before {
    content: '';
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid #26222c;
    border-bottom: 2px solid #26222c;
    transform: scaleY(2);
    opacity: 0;
    transition: .3s;
  }
  &:after {
    text-decoration: none;
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #26222c;
    transform: scale(0);
    opacity: 0;
    transition: .3s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    &:before {
      transform: scaleY(1);
      opacity: 1;
    }
    &:after {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}





//Footer -------------------------------------------------------

.footer-container 
{
  margin-top:5rem;
  width: 100vw;
  height: 5vh;
  background: #17151b;
  display: flex;
  justify-content: center;
  align-items: center;
  

}

.footer-container p {
  font-size: 1rem;
}

//Media Query

/* (1680x1050) WSXGA+ Display */
@media  screen and (max-width: 1680px) {

  .header-middle-contaier
  {
    margin-top: 14rem;
    width: 90%;
  }
}

/* (1440x900) WXGA+ Display */
@media  screen and (max-width: 1440px) {
  /* insert styles here */
  .app {
    width:100vw;
  }
  .header-middle-contaier h1{
font-size: 8rem;
  }
}
/* (1280x1024) SXGA Display */
@media  screen and (max-width: 1280px) {
  /* insert styles here */
  .app {
    width:100vw;
  }
  .nav-Container a{
    font-size: 1.5rem;  
  }
  .header-middle-contaier{
    margin-top: 5rem;
  }
  .header-middle-contaier h1{
    font-size: 7rem;
      }
}

@media only screen and (max-width: 767px) {
  /* phones */
  .app{
    width: 100vh;
  }

.nav-Container a {
margin-top: -0.5rem;
padding: 1.4rem;
}
.header-middle-contaier h2 {
  font-size:1.4rem;
  margin-bottom: 1rem;
}
.header-middle-contaier h1 .cat {
  
  font-size: 7rem;
  padding: 0;
}
.header-middle-contaier h1{
  font-size: 4rem;
  padding: 0;
}
.last-name{
  margin-top: -1rem;
}

.please-work {
  width: 85%;
}

.image-container p {
  font-size: 1rem;
  text-align: center;
}

.contact-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-around;
}

.text-container {
  font-size: 8rem;
}

.project-left{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-right{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-left-image{
  height: 100%;
  width: 100%;
}
.p-left-content{
width: 100%;
}

#container h2{
 text-align: center;
}
}

@media only screen and (min-width: 320px) and (max-width: 479px){ 

  .nav-Container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
  .nav-Container a {
    padding: 1rem;
    }
    .header-middle-contaier{
      width: 100vw;
    }

    .header-middle-contaier h2 {
      font-size:1.2rem;
      margin-bottom: 0.2rem;
    }
    .header-middle-contaier h1 .cat {
      font-size: 3.6rem;
      padding: 0;
    }
    .header-middle-contaier h1{
      font-size: 2rem;
      letter-spacing: 0.1rem;
      
    }

    .header-middle-contaier p {
      margin-top: -1rem;
      font-size: 1.2rem;
    }
    .last-name{
      margin-top: -3rem;
    }
    
    .please-work {
      width: 85%;
    }
    
    .image-container p {
      font-size: 1rem;
      text-align: center;
    }

    .grid-container{
      display: flex;
      flex-direction: column;
    }

    .contact-icons{
      flex-direction: row;
    }

    .text-container{
      font-size: 4.5rem;
      padding: 4rem;
    }

    .project-left{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .project-right{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .p-left-image{
      height: 100%;
      width: 100%;
    }

    .project-img{ 
      height: 100%;
      width: 100%;
    }
    .p-left-content{
    width: 100%;
    }

    .left-buttons{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .left-title{
      font-size: 4.3rem;
    }

    .left-links{
      font-size: 1rem;
    }
    
    #container h2{
     text-align: center;
    }
    .project-about-p{
      width: 100%;
    }

    #container h1 {
      font-size: 5rem;
      text-align: center;
      
    }

    #container h2 {
      font-size: 3rem;
    }

    .footer-container p {
      text-align: center;
    }

    .header-container-dark{
      height: auto;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    .header-middle-contaier{
      margin-bottom: 4rem;
    }
    .about h1{
      font-size: 3.6rem;
    }
 }
